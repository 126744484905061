import React, { createContext, useState, useContext } from "react";

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ show: false, message: "", status: "" });

  const showToast = (message, status) => {
    setToast({ show: true, message, status });
    setTimeout(() => {
      setToast({ show: false, message: "" });
    }, 8000);
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast.show && (
        <div
          className="toast-container position-fixed top-0 end-0 p-3"
          style={{ zIndex: 1000 }}
          onClick={() => setToast({ show: false, message: "" })}
        >
          <div
            className="toast show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            style={{
              background:
                toast.status === "success"
                  ? "linear-gradient(109.6deg, rgb(0, 204, 130) 11.2%, rgb(58, 181, 46) 91.7%)"
                  : "linear-gradient(111.4deg, rgb(246, 4, 26) 0.4%, rgb(251, 139, 34) 100.2%)",
              color: "#ffffff",
            }}
          >
            <div
              className="toast-header"
              style={{ background: "transparent", color: "#ffffff" }}
            >
              <strong className="me-auto text-capitalize">
                {toast.status}
              </strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setToast({ show: false, message: "" })}
              ></button>
            </div>
            <div className="toast-body">{toast.message}</div>
          </div>
        </div>
      )}
    </ToastContext.Provider>
  );
};
