import React, { useEffect, useState } from "react";
import RKClogo from "../../assets/img/RKC Logo 2.svg";
import linkedInlogo from "../../assets/img/linkedin.svg";
import facebookLogo from "../../assets/img/facebook.svg";
import twitterLogo from "../../assets/img/twitter.svg";
import mailLogo from "../../assets/img/envelope.svg";
import callLogo from "../../assets/img/call.svg";
import locationLogo from "../../assets/img/location.svg";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = ({ footerData }) => {
  return (
    <footer className="site-footer main-footer">
      <div className="container custom-container">
        <div className="footer-wrapper">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-4 col-sm-12 aos aos-animate"
              data-aos="fade-left"
              data-aos-duration={900}
            >
              <div className="footer-left">
                <div className="site-logo pb-3">
                  <img src={RKClogo} alt="img-alt" className="img-fluid" />
                </div>
                <div className="footer-dec">
                  <p className="cmn-text">
                    {footerData && footerData.footer_description}
                  </p>
                </div>
                <div className="sm-list">
                  <a
                    target="_blank"
                    href={footerData && footerData.linkedin}
                    class="linkedin sm-icon"
                    rel="noreferrer"
                  >
                    <img src={linkedInlogo} />
                  </a>
                  <a
                    href={footerData && footerData.facebook}
                    target="_blank"
                    class="facebook sm-icon"
                  >
                    <img src={facebookLogo} />
                  </a>
                  <a
                    href={footerData && footerData.twitter}
                    target="_blank"
                    class="twitter sm-icon"
                  >
                    <img src={twitterLogo} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-8 col-lg-8 col-md-8 col-sm-12 aos aos-animate"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              <div className="footer-right">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="site-map getin-touch-row">
                      <h6 className="footer-title footer-cmn-title pb-xl-4 pb-lg-4 pb-md-4 pb-sm-3 pb-3">
                        Get in Touch
                      </h6>
                      <div className="contact-link">
                        <a
                          href={`mailto:${footerData && footerData.email}`}
                          className="cta-mail link-text"
                        >
                          <img src={mailLogo} />
                          {footerData && footerData.email}
                        </a>
                      </div>
                      <div className="contact-link">
                        <a
                          href={`tel:+91-${footerData && footerData.mobile}`}
                          className="cta-tel link-text"
                        >
                          <img src={callLogo} />
                          +91-
                          {footerData && footerData.mobile}
                        </a>
                      </div>
                      <div>
                        <address className="site-address link-text">
                          <img src={locationLogo} />
                          {footerData && footerData.address}
                        </address>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                    <div className="site-map">
                      <h6 className="footer-title footer-cmn-title pb-xl-4 pb-lg-4 pb-md-4 pb-sm-3 pb-3">
                        Sitemap
                      </h6>
                      <nav className="footer-menu">
                        <ul className="site-links">
                          <li>
                            <Link to="/projects" className="link-text">
                              Projects
                            </Link>
                          </li>
                          <li>
                            <Link to="/gallery" className="link-text">
                              Gallery
                            </Link>
                          </li>
                          <li>
                            <Link to="/careers" className="link-text">
                              Careers
                            </Link>
                          </li>
                          <li>
                            <Link to="/b2b" className="link-text">
                              B2B
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact" className="link-text">
                              Contact us
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                    <div className="site-map">
                      <h6 className="footer-title footer-cmn-title pb-xl-4 pb-lg-4 pb-md-4 pb-sm-3 pb-3">
                        Company
                      </h6>
                      <nav className="footer-menu">
                        <ul className="site-links">
                          <li>
                            <Link to="/" className="link-text">
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to="/about" className="link-text">
                              About
                            </Link>
                          </li>
                          <li>
                            <HashLink
                              to="/about#timeline-sec"
                              className="link-text"
                            >
                              Timeline
                            </HashLink>
                          </li>
                          <li>
                            <HashLink
                              to="/about#certificate"
                              className="link-text"
                            >
                              Certificate
                            </HashLink>
                          </li>
                          <li>
                            <HashLink
                              to="/about#turnover"
                              className="link-text"
                            >
                              Financial info
                            </HashLink>
                          </li>
                          <li>
                            <Link to="/policies" className="link-text">
                              Policies
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btm">
        <div className="container custom-container">
          <div className="d-flex justify-content-between">
            <div className="copyright">
              <div className="btm-left">
                <p className="cmn-text">
                  {footerData && footerData.copy_right}
                </p>
                <p className="cmn-text">
                  Designed & Developed by{" "}
                  <a style={{ color: "#188ac6" }} target="_blank" href="https://www.shineinfosoft.in/">
                    Shine Infosoft
                  </a>
                </p>
              </div>
            </div>
            <div className="btm-right">
              <Link
                to="/policies/privacy-policy"
                className="privacy-policy pe-3 link-text"
              >
                Privacy-policy
              </Link>
              <Link
                to="/policies/terms-of-use"
                className="terms-condition link-text"
              >
                Terms &amp; Condition
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
