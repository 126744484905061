import axios from "axios";
import { useContext, useEffect, useState } from "react";
import MyContext from "../LoaderContext";

const useFetch = (url) => {
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { setLoader } = useContext(MyContext);

  useEffect(() => {
    const payloadUrl = `https://admin.rkc.co.in${url}`;
    const fetchData = async () => {
      setLoader(true);
      try {
        const res = await axios.get(payloadUrl);
        const { data } = res;
        if (!data) {
          setError("Not enough data available.");
        } else {
          setResData(data);
          setError("");
        }
        setLoader(false);
      } catch (err) {
        setLoader(false);
        setError(err.message || "An error occurred");
      } finally {
        setLoader(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { resData, loading, error };
};

export default useFetch;
